<template>
    <v-dialog v-model="visible" mmax-width="600px">
        <v-card>
            <v-card-title>区域选择界面</v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-select
                                v-model="region.province"
                                :rules="rules.province"
                                :items="provinceList"
                                label="省"
                                hint="请选择省"
                                @change="selectCity"
                                prepend-icon="mdi-flag"
                                clearable
                                clear-icon="mdi-close-circle"
                        ></v-select>
                        <v-select
                                v-model="region.city"
                                :rules="rules.city"
                                :items="cityList"
                                label="市"
                                hint="请选择市"
                                prepend-icon="mdi-city"
                                clearable
                                clear-icon="mdi-close-circle"
                        ></v-select>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  :disabled="!valid" color="blue darken-1"  text large @click="confirmRegion">确定</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackbar" centered>
            {{message}}
        </v-snackbar>
    </v-dialog>
</template>

<script>

    export default {
        name: "RegionModal",
        data(){
            return{
                visible: false,
                region: {
                    province: null,
                    city: null,
                },
                rules: {
                    province: [
                        v => !!v || '省是必选项',
                    ],
                    city: [
                        v => !!v || '市是必选项',
                    ],
                },
                provinceList: [],
                cityList: [],
                valid: true,
                message: null,
                snackbar: false,
            }
        },
        methods: {
            show(){
                this.visible = true;
                if (this.region.province){
                    this.selectCity('city');
                }
            },
            confirmRegion(){
                if (this.$refs.form.validate()){
                    this.visible = false;
                    this.$emit('close',this.region);
                }
            },
            selectCity(any){
                if (any){
                    this.$http.get('apply/getCity', {
                        params: {
                            province: this.region.province
                        }})
                        .then(({data}) => {
                            this.cityList = data.cityList;
                        }).catch(() => {
                        this.message = '网络请求失败!';
                        this.snackbar = true;
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
